import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { BcpAsyncLoader } from '@bcp/js-widgets';  
import { WidgetsHookService } from '../infraestructure/services/widgetshook.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    WidgetsHookService,
    {
      provide: BcpAsyncLoader,
      useFactory: (widgetsHooksService: WidgetsHookService) =>
        new BcpAsyncLoader(widgetsHooksService),
      deps: [WidgetsHookService],
    },
  ],
})
export class WidgetsModule {}
