import { Injectable, ViewContainerRef } from '@angular/core';
import { ErrorComponent } from './error.component';
import { MessageError } from './message-error';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    viewContainerRef!: ViewContainerRef;

    open(msgError: MessageError) {
        this.viewContainerRef.clear();
        const ref = this.viewContainerRef.createComponent(ErrorComponent);
        if (msgError) { ref.instance.messageError = msgError; }
        ref.changeDetectorRef.detectChanges();
    }

    close() {
        this.viewContainerRef.remove();
    }
}
