import { Component, ViewContainerRef } from '@angular/core';
import { ModalService } from './contexts/login/infrastructure/core/components/modal/modal.service';
import { ProgressIndicatorService } from './contexts/login/infrastructure/core/components/progress-indicator/progress-indicator.service';
import { CorrectValidationService } from './contexts/login/infrastructure/core/components/correct-validation/correct-validation.service';
import { ErrorService } from './contexts/login/infrastructure/core/components/error/error.service';
import { SuccessService } from './contexts/login/infrastructure/core/components/success/success.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'baut-web-authentication-v1';

  constructor(
    private viewContainerRef: ViewContainerRef,
    private modalService: ModalService,
    private progressIndicatorService: ProgressIndicatorService,
    private correctValidationService: CorrectValidationService,
    private errorService: ErrorService,
    private successService: SuccessService
  ) {
    this.modalService.viewContainerRef = this.viewContainerRef;
    this.progressIndicatorService.viewContainerRef = this.viewContainerRef;
    this.correctValidationService.viewContainerRef = this.viewContainerRef;
    this.errorService.viewContainerRef = this.viewContainerRef;
    this.successService.viewContainerRef = this.viewContainerRef;
  }
}
