import { LoginTimer } from './login-timer';
import { Timer } from './timer';
import { LoginTimerConfigurator } from './login-timer-configurator';

export class LoginFactory {
  constructor(
    private configuration: {
      timer: Timer;
      loginTimerConfigurator: LoginTimerConfigurator;
    }
  ) { }

  createLoginTimer() {
    const { timer, loginTimerConfigurator } = this.configuration;

    return new LoginTimer({ timer, loginTimerConfigurator });
  }
}
