import { Injectable, ViewContainerRef } from '@angular/core';
import { CorrectValidationComponent } from './correct-validation.component';

@Injectable({
  providedIn: 'root'
})
export class CorrectValidationService {
  viewContainerRef!: ViewContainerRef;

  open() {
    this.viewContainerRef.clear();
    const ref = this.viewContainerRef.createComponent(CorrectValidationComponent);
    ref.changeDetectorRef.detectChanges();
  }

  close() {
    this.viewContainerRef.remove();
  }
}
