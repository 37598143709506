import { Injectable, ViewContainerRef } from '@angular/core';
import { ProgressIndicatorComponent } from './progress-indicator.component';

@Injectable({
  providedIn: 'root'
})
export class ProgressIndicatorService {

  viewContainerRef!: ViewContainerRef;

  open() {
    this.viewContainerRef.clear();
    const ref = this.viewContainerRef.createComponent(ProgressIndicatorComponent);
    ref.instance.openProgress();
    ref.changeDetectorRef.detectChanges();
  }

  close() {
    this.viewContainerRef.remove();
  }
}
