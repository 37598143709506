<div class="container">
  <div class="col-md-6 mx-auto px-0">
    <div class="row justify-content-center mt-4" *ngIf="showWarning">
      <div class="col-11 px-0 box-error">
        <div class="d-flex col-11 h-100 align-items-center">
          <bcp-icon name="exclamation-triangle-b" size="22" class="pr-3"></bcp-icon>
          <bcp-paragraph size="md" family="regular" color="text">Clave incorrecta</bcp-paragraph>
        </div>
        <div class="d-flex col-1 px-0 align-items-center" (click)="closeWarning()">
          <bcp-icon name="close-r" size="22"></bcp-icon>
        </div>
      </div>
    </div>
    <div class="row pt-4">
      <div class="col">
        <bcp-title data-testid="nameClient" size="lg" font="bold">¡Hola, {{customer.nameClient}}!</bcp-title>
        <bcp-title size="lg" font="bold">Validaremos tus datos</bcp-title>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col">
        <bcp-paragraph size="lg" family="regular">Por favor, ingresa tu Clave de Internet de 6 dígitos.</bcp-paragraph>
      </div>
    </div>
    <div class="row" style="margin-top: 2rem;">
      <form class="col">
        <bcp-input-password #pwd label="Ingresa tu clave de 6 dígitos" accessible-name-icon-trash="Vaciar"
          accessible-name-icon-backspace="Borrar" bcpCtrlForm [formControl]="passwordControl" [leftText]="'Crear Clave'"
          [rightText]="'Olvidé mi Clave'" [visible]="visible" (leftTextClick)="openModalToCreatePassword()"
          (rightTextClick)="openModalToForgotPassword()">
        </bcp-input-password>

        <br>
        <div class="col-md-6 offset-md-3" *ngIf="passwordControl.value?.keys?.length === 6">
          <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (ctrlClick)="sendForm()">
            Continuar
          </bcp-button>
        </div>
      </form>
    </div>
  </div>

</div>