import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ProgressIndicatorModule } from './progress-indicator/progress-indicator.module';
import { ModalModule } from './modal/modal.module';
import { CorrectValidationModule } from './correct-validation/correct-validation.module';
import { ErrorModule } from './error/error.module';
import { SuccessModule } from './success/success.module';

const MODULES = [
  ProgressIndicatorModule,
  ModalModule,
  CorrectValidationModule,
  ErrorModule,
  SuccessModule
];

@NgModule({
  imports: [...MODULES],
  exports: [...MODULES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreComponentsModule { }
