import { NgModule, InjectionToken } from '@angular/core';
import { LoginTimerConfiguratorRepository } from './repository/login-timer-configurator.repository';
import { CustomerHttpRepository } from './repository/customer-http.repository';
import { LoginFactory } from '../domain/login-factory';
import { ClientTimer } from './services/client-timer';
import { RepositoryModule } from './repository/repository.module';
import { CreateCustomer } from '../application/create-customer';

export const LOGIN_FACTORY = new InjectionToken<LoginFactory>('LoginFactory');
export const CREATE_CUSTOMER = new InjectionToken<CreateCustomer>('createCustomer');

@NgModule({
  imports: [
    RepositoryModule
  ],
  providers: [
    {
      provide: LOGIN_FACTORY,
      useFactory: () => {
        return new LoginFactory({
          timer: new ClientTimer(),
          loginTimerConfigurator: new LoginTimerConfiguratorRepository(),
        });
      },
    },
    {
      provide: CREATE_CUSTOMER,
      useFactory: (customerRepository: CustomerHttpRepository) => {
        return new CreateCustomer({ customerRepository });
      },
      deps: [CustomerHttpRepository]
    }
  ]
})
export class FactoryCoreModule { }
