import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-success',
  template: `<bcp-navbar theme="dark" brand-logo="/assets/img/logos/bcp-dark-default.svg" fluid></bcp-navbar>
  <div class="container pt-5">
    <div class="col-md-6 mx-auto px-0">
      <div class="row" [align]="'center'">
        <div class="col"><img src="/assets/svg/success_img.svg" alt="Validado correcto">
          <div class="py-4">
            <bcp-title size="md" family="regular" color="primary-700">¡Felicitaciones!</bcp-title>
            <bcp-title size="md" family="demi" color="primary-700">Ya puedes seguir con tus consultas en el WhatsApp del
              BCP</bcp-title>
          </div>
          <div class="mt-4 mb-5">
            <bcp-paragraph family="demi">
              En breve te llegará una notificación a tu WhatsApp confirmando tu inicio de sesión
            </bcp-paragraph>
          </div>
          <div class="col-md-6 py-4">
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (ctrlClick)="redirectWsp()">
              Volver al WhatsApp del BCP
            </bcp-button>
          </div>
        </div>
      </div>
    </div>
  </div>`,
})
export class SuccessComponent {

  redirectWsp() {
    window.open(`https://wa.me/${environment.NumberWsp}`, '_blank', 'noopener');
  }
}
