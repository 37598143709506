import { HttpClient, HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { Customer } from '../../domain/customer';
import { Injectable, inject } from '@angular/core';
import { CustomerRepository } from '../../domain/customer-repository';
import { environment } from 'src/environments/environment';

@Injectable()
export class CustomerHttpRepository implements CustomerRepository {
  private readonly httpClient = inject(HttpClient);
  private API_URL = `${environment.APIUX}/channel/baut/v1/data-sessions`;

  async getDataCustomer(params:Params): Promise<Customer> {
    let queryParams = new HttpParams()
      .append("conversationId", params['conversationId'])
      .append("channelId", params['channelId'])
      .append("chatbotId", params['chatbotId']);

    let url = `${this.API_URL}/${params['customerId']}`;
    return firstValueFrom(this.httpClient.get<Customer>(`${url}`, { params: queryParams }));
  }
}