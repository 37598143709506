import { Component, OnInit, inject } from '@angular/core';
import { bcpPipeRepository } from '@bcp/js-networking/pipe';
import { CREATE_CUSTOMER, LOGIN_FACTORY } from '../../factory-core.module';
import { Customer } from '../../../domain/customer';
import { KeyboardSend, Session } from '../../../domain/session';
import { SESSION_REPOSITORY } from '../../repository/repository.module';
import { ProgressIndicatorService } from '../../core/components/progress-indicator/progress-indicator.service';
import { CorrectValidationService } from '../../core/components/correct-validation/correct-validation.service';
import { ErrorMessageFactory } from '../../core/components/error/error-message-factory';
import { ErrorService } from '../../core/components/error/error.service';
import { SuccessService } from '../../core/components/success/success.service';
import { firstValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { InsightService } from '../../services/insight.service';
import { LogInsight } from '../../../domain/log-insight';
import { MessageError } from '../../core/components/error/message-error';

@Component({
  selector: 'app-input-password-view',
  templateUrl: './input-password-view.component.html'
})
export class InputPasswordViewComponent implements OnInit {
  private readonly loginFactory = inject(LOGIN_FACTORY);
  private readonly createCustomer = inject(CREATE_CUSTOMER);
  private readonly sessionHttpRepository = inject(SESSION_REPOSITORY);
  private readonly loginTimer = this.loginFactory.createLoginTimer();
  private readonly errorMessageFactory = new ErrorMessageFactory();
  private readonly progressIndicatorService = inject(ProgressIndicatorService);
  private readonly correctValidationService = inject(CorrectValidationService);
  private readonly errorService = inject(ErrorService);
  private readonly successService = inject(SuccessService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly insightService = inject(InsightService);

  customer!: Customer;
  empty!: Customer;
  showWarning: boolean = false;
  showNav: boolean = true;
  errorCount: number = sessionStorage.getItem('EC') ? parseInt(sessionStorage.getItem('EC')!) : 0;
  numAttempt: number = 0;
  successValidate: boolean = false;
  remainingTime = this.loginTimer.getRemainingTime();
  params: any;

  async ngOnInit() {
    this.progressIndicatorService.open();

    if (this.errorCount === 3) {
      this.showErrorPage(this.errorMessageFactory.attempsPassed());
      return;
    }

    if (this.remainingTime === 0) {
      this.sessionExpiredPage();
      return;
    }

    this.params = await this.getParams();

    await this.createCustomer.run(this.params)
      .then((customer: Customer) => {
        this.progressIndicatorService.close();
        this.customer = customer;
        this.startCountdown();
        let logInsight: LogInsight = { statusCode: 200, statusText: 'Ok', params: this.params, message: 'Respuesta Ok del api DataSession' };
        this.insightService.trackTrace(`${JSON.stringify(logInsight)}`)
      })
      .catch((error) => {
        let msgErrorView = error?.error?.exceptionDetails && error?.error?.exceptionDetails[0]?.code === "DP0005" ? this.errorMessageFactory.linkExpired() :
          this.errorMessageFactory.serviceErrorGeneral();
        this.showErrorPage(msgErrorView);
        let logInsight: LogInsight = { statusCode: error?.status, statusText: error?.statusText, params: this.params, message: 'Error del servicio Get Data Session', response: error?.error };
        this.insightService.trackException(`${JSON.stringify(logInsight)}`)
      });
  }

  async getParams() {
    return await firstValueFrom(this.activatedRoute.queryParams);
  }

  startCountdown() {
    this.loginTimer.startCountdown({
      callbackRemainingTime: (remainingTime) => {
        this.remainingTime = remainingTime;
        if (this.remainingTime === 0 && !this.successValidate) {
          this.sessionExpiredPage();
        }
      },
    });
  }

  sessionExpiredPage() {
    this.showErrorPage(this.errorMessageFactory.sessionExpired());
    let logInsight: LogInsight = { params: this.params, message: 'La sesion del navegador expiró.' };
    this.insightService.trackTrace(`${JSON.stringify(logInsight)}`)
  }

  showErrorPage(messageError: MessageError) {
    this.showNav = false;
    this.customer = this.empty;
    this.progressIndicatorService.close();
    this.errorService.open(messageError);
  }

  async handleFormValue(keyboard: KeyboardSend) {
    this.progressIndicatorService.open();
    let logInsight: LogInsight = { params: this.params, message: `Customer Session post: ${JSON.stringify(keyboard)}` };
    this.insightService.trackTrace(`${JSON.stringify(logInsight)}`)

    await bcpPipeRepository({
      request: () => this.sessionHttpRepository.sendDataSession(this.params, { keyboard }),
      success: (session: Session) => {
        this.showNav = false;
        this.successValidate = true;
        this.remainingTime = 0;
        this.progressIndicatorService.close();
        this.correctValidationService.open();
        let logInsight: LogInsight = { statusCode: 200, statusText: 'Ok', params: this.params, message: 'Respuesta Ok del api POST CustomerSession', response: session };
        this.insightService.trackTrace(`${JSON.stringify(logInsight)}`);
        this.customer = this.empty;
        sessionStorage.removeItem('EC');
        setTimeout(() => {
          this.correctValidationService.close();
          this.successService.open();
        }, 1500);

      },
      error: async (error) => {
        let logInsight: LogInsight = { statusCode: error?.status, statusText: error?.statusText, params: this.params, message: 'Error del servicio Post Customer Session', response: error?.error };
        this.insightService.trackException(`${JSON.stringify(logInsight)}`);

        if (error?.status === 500) {
          this.showErrorPage(this.errorMessageFactory.serviceErrorGeneral());
          return;
        }

        if (error?.error?.exceptionDetails && error?.error?.exceptionDetails[0]?.code === "DP0011") {
          this.showErrorPage(this.errorMessageFactory.cardBlocked());
          return;
        }

        this.showWarning = false;
        this.errorCount++;
        sessionStorage.setItem('EC', this.errorCount.toString());
        if (this.errorCount === 1) {
          this.showWarning = true;
          await this.callGetData();
        } else if (this.errorCount === 2) {
          await this.callGetData();
        } else {
          this.showErrorPage(this.errorMessageFactory.attempsPassed());
        }
      },
    });
  }

  async callGetData() {
    await this.createCustomer.run(this.params)
      .then((customer: any) => {
        this.customer = customer;
        this.numAttempt = this.errorCount;
        this.progressIndicatorService.close();
        let logInsight: LogInsight = { statusCode: 200, statusText: 'Ok', params: this.params, message: 'Respuesta Ok del api Get DataSession en reintento' };
        this.insightService.trackTrace(`${JSON.stringify(logInsight)}`);
      })
      .catch((error) => {
        this.errorCount = 0;
        let msgErrorView = error?.error?.exceptionDetails && error?.error?.exceptionDetails[0]?.code === "DP0005" ? this.errorMessageFactory.linkExpired() :
          this.errorMessageFactory.serviceErrorGeneral();
        this.showErrorPage(msgErrorView);
        let logInsight: LogInsight = { statusCode: error?.status, statusText: error?.statusText, params: this.params, message: 'Error del servicio Get Data Session en reintento', response: error?.error };
        this.insightService.trackException(`${JSON.stringify(logInsight)}`);
      });
  }
}
