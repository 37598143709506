import { Injectable, ViewContainerRef } from '@angular/core';
import { ModalComponent } from './modal.component';
import { MessageModal } from './message-modal';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  viewContainerRef!: ViewContainerRef;

  open(msgModal: MessageModal, height: number) {
    this.viewContainerRef.clear();
    const ref = this.viewContainerRef.createComponent(ModalComponent);
    if (msgModal) { ref.instance.messageModal = msgModal; }
    if (height !== 0) { ref.instance.heigth = height; }
    ref.instance.openModal();
    ref.changeDetectorRef.detectChanges();
  }

  close() {
    this.viewContainerRef.remove();
  }
}
