import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BcpLocalStorage } from '@bcp/js-storage';
import { environment } from 'src/environments/environment';
import { authenticationBiom } from '../../domain/model/authenticationBiom.model';
import { validationBiom } from '../../domain/model/validationBiom.model';
import { LogInsight } from 'src/app/contexts/login/domain/log-insight';
import { InsightService } from 'src/app/contexts/login/infrastructure/services/insight.service';

@Injectable({
  providedIn: 'root'
})
export class BiometricService {

  storage = new BcpLocalStorage();
  httpClient = inject(HttpClient);
  API_URL = `${environment.APIUX}/biom/channel/baut/`;
  constructor(private http: HttpClient, private insightService : InsightService ) {  
  }

  async authenticate(){ 
    let customerId = sessionStorage.getItem('customerId');
    let conversationId = sessionStorage.getItem('conversationId');
    let channelId = sessionStorage.getItem('channelId');
    let chatbotId = sessionStorage.getItem('chatbotId');
    let urlAuth = `${this.API_URL}${customerId}`;
 
    const params = {
      'customerId' : customerId,
      'conversationId' : conversationId,
      'channelId' : channelId,
      'chatbotId' : chatbotId
   }

    try { 
        const result = await this.http.post<authenticationBiom>(urlAuth + `/validate?conversationId=${conversationId}&channelId=${channelId}&chatbotId=${chatbotId}`,{} ).toPromise();
      
        if (result?.biometricCode) {
          this.storage.removeItem('errorAuthentication');
           if(!this.excTime()){
            this.storage.setItem('secondTime', String(environment.biomFacialVendor.waitTimeInSeconds));
            this.storage.setItem('initTime', 'FALSE');
          }
          this.storage.setItem('uidBiometric', result.biometricCode);

          const params = {
             'customerId' : customerId,
             'conversationId' : conversationId,
             'channelId' : channelId,
             'chatbotId' : chatbotId
          }

          let logInsight: LogInsight = { statusCode: 200, statusText: 'Ok', params: params, message: 'Respuesta Ok del endpoint customer-biometric-sessions/{customer-id-hash}/validate' };
          this.insightService.trackTrace(`${JSON.stringify(logInsight)}`)

          return true;
        } else {
          this.clearDataBiom(); 
          let logInsight: LogInsight = {  params: params, message: 'Respuesta Error BiometricCode del endpoint customer-biometric-sessions/{customer-id-hash}/validate' };
          this.insightService.trackTrace(`${JSON.stringify(logInsight)}`)
          return false;
        }
      } catch (error : any) { 
        this.clearDataBiom();
        let logInsight: LogInsight = { statusCode: error?.status, statusText: error?.statusText, params: params, message: 'Error del servicio del endpoint customer-biometric-sessions/{customer-id-hash}/validate', response: error?.error };
        this.insightService.trackException(`${JSON.stringify(logInsight)}`)
        return false; 
      }
  
  }

  dataInfo(customerId : string, conversationId:string, channelId:string, chatbotId:string){
    sessionStorage.setItem('customerId', customerId);
    sessionStorage.setItem('conversationId', conversationId);
    sessionStorage.setItem('channelId', channelId);
    sessionStorage.setItem('chatbotId', chatbotId);
  }

  async validation(facialKey : string = ''){
    
    let customerId = sessionStorage.getItem('customerId');
    let conversationId = sessionStorage.getItem('conversationId');
    let channelId = sessionStorage.getItem('channelId');
    let chatbotId = sessionStorage.getItem('chatbotId');

    let urlAuth = `${this.API_URL}${customerId}`; 

    const params = {
      'customerId' : customerId,
      'conversationId' : conversationId,
      'channelId' : channelId,
      'chatbotId' : chatbotId,
      'seed' : facialKey
   } 

    try { 

        const result = await this.http.post<validationBiom>(urlAuth + `?conversationId=${conversationId}&channelId=${channelId}&chatbotId=${chatbotId}&seed=${facialKey}`,{} ).toPromise();
       
        if (result?.sessionId) {
          sessionStorage.setItem('session',result?.sessionId);
          this.clearDataBiom(); 

          let logInsight: LogInsight = { statusCode: 200, statusText: 'Ok', params: params, message: 'Respuesta Ok del endpoint customer-biometric-sessions/{customer-id-hash}' };
          this.insightService.trackTrace(`${JSON.stringify(logInsight)}`)

          return true;
        } else { 
          this.clearDataBiom();
          let logInsight: LogInsight = {  params: params, message: 'Respuesta Error sessionId del endpoint customer-biometric-sessions/{customer-id-hash}' };
          this.insightService.trackTrace(`${JSON.stringify(logInsight)}`)
          return false;
        }
      } catch (error : any) { 
        let logInsight: LogInsight = { statusCode: error?.status, statusText: error?.statusText, params: params, message: 'Error del servicio del endpoint customer-biometric-sessions/{customer-id-hash}', response: error?.error };
        this.insightService.trackException(`${JSON.stringify(logInsight)}`)
        return false;  
      }
  } 

  biometricCode(){
    return this.storage.getItem('uidBiometric') ? this.storage.getItem('uidBiometric')  : '';
  }

  guardsecondTime(valor : number){
    this.storage.setItem('secondTime',String(valor)); 
    return valor;
  }

  secondTime(){ 
    return this.storage.getItem('secondTime') ? Number(this.storage.getItem('secondTime'))  : 0;
  }

  initTime(){ 
    return this.storage.getItem('initTime') ? true  : false;
  }

  excTime(){
    return this.storage.getItem('secondTime') ? true  : false;
  }

  finishTime(){ 
    if(this.storage.getItem('secondTime')){
      if( Number(this.storage.getItem('secondTime')) > 0){
        return false;
      }else{
        return true;
      }
    }else{
      return false;
    }
  }

  errorAuthentication(){
    return this.storage.getItem('errorAuthentication') == 'true' ? true : false;
  }

  invalide(){
    //LOCALSTORAGE
    this.storage.removeItem('accessToken');
    this.storage.removeItem('tokenCasWD');
    this.storage.removeItem('uidBiometric');
    this.storage.removeItem('secondTime');
//SESSION
    sessionStorage.removeItem('customerId');
    sessionStorage.removeItem('conversationId');
    sessionStorage.removeItem('channelId');
    sessionStorage.removeItem('chatbotId');
    this.storage.setItem('errorAuthentication', 'true');
    return true;
  }
  

  clearDataBiom(){
    //LOCALSTORAGE
    this.storage.removeItem('uidBiometric');
    this.storage.removeItem('secondTime');
    this.storage.removeItem('initTime');
    this.storage.removeItem('accessToken');
    this.storage.removeItem('tokenCasWD');
    //SESSION
    sessionStorage.removeItem('customerId');
    sessionStorage.removeItem('conversationId');
    sessionStorage.removeItem('channelId');
    sessionStorage.removeItem('chatbotId');
  }

  clearErrorAuthentication(){
    this.storage.removeItem('secondTime');
  }
 

  
}
