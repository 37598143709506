import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router'; 
import { Observable } from 'rxjs';
import { BcpLocalStorage } from '@bcp/js-storage';
import { BiometricService } from '../../infraestructure/services/biometric.service';
 

@Injectable({
  providedIn: 'root',
})
export class OidcGuard {
  storage = new BcpLocalStorage();
  constructor(  private biometricService : BiometricService) { 
  }
  canActivate(
    next: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree { 
    const customerId = next.queryParams['customerId'];
    const conversationId = next.queryParams['conversationId'];
    const channelId = next.queryParams['channelId'];
    const chatbotId = next.queryParams['chatbotId']; 
    
    if(!customerId || !conversationId || !channelId || !chatbotId) return this.invalide();   
    this.biometricService.dataInfo(customerId,conversationId,channelId,chatbotId);
    return true; 
  } 

  invalide(){
    this.biometricService.invalide();
    return true;
  }
}