<footer class="footer">
  <div class="div-top">
    <div>
      <bcp-icon class="lock-icon" name="lock-b"></bcp-icon>
    </div>
    <div class="div-paragraph">
      <bcp-paragraph size="sm" family="regular"  color="onsurface-400">
      Esta es una página segura del BCP. Si tienes dudas sobre la autenticidad de la web, comunícate con<br>nosotros al (01) 311-9898 o a través de nuestros medios digitales.
    </bcp-paragraph>
    </div>
  </div>
  <div class="div-midd"></div>
  <div class="div-bottom">
        <bcp-paragraph size="sm" family="regular" color="onsurface-400">
          Banco de Crédito del Perú S.A - RUC: 20100047218
        </bcp-paragraph> 
      <div class="separation"></div> 
        <bcp-paragraph size="sm" family="regular" color="onsurface-400">
          Todos los derechos reservados 
        </bcp-paragraph> 
      <div class="separation"></div> 
        <bcp-paragraph size="sm" family="regular" color="onsurface-400">
          © 2024 VIABCP.com 
        </bcp-paragraph> 
         
  </div> 
 
</footer>