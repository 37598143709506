import { Injectable, inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthManager } from './auth-manager';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly authManager = inject(AuthManager)

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.authManager.isValidToken()) return next.handle(req);
    const authReq = req.clone({
      setHeaders: { 'Authorization': `Bearer ${this.authManager.getToken()}` }
    });
    return next.handle(authReq);
  }
}
