import { MessageModal } from '../../../core/components/modal/message-modal';

export class ModalMessageFactory {
  forgotPassword(): MessageModal {
    return {
      titleModal: '¿Olvidaste tu Clave de Internet de 6 dígitos?',
      descModal: 'Recupérala desde tu Banca Móvil o comunícate con nuestra Banca por Teléfono al 311-9898 y uno de nuestros asesores te ayudará.',
      textBtnModal: 'Entendido',
      actionBtnModal: ''
    };
  }

  createPassword(): MessageModal {
    return {
      titleModal: 'Crea tu Clave de Internet',
      descModal: 'Al presionar “Entendido” te llevaremos a una página oficial del BCP para crear tu clave. Luego, vuelve a escribirnos al WhatsApp del BCP para iniciar sesión.',
      textBtnModal: 'Entendido',
      actionBtnModal: 'redirectTo'
    };
  }

  lastAttemp(): MessageModal {
    return {
      titleModal: 'Último intento',
      descModal: 'La clave es incorrecta. Asegúrate de ponerla correctamente, de lo contrario, tu tarjeta será bloqueada.',
      textBtnModal: 'Entendido',
      actionBtnModal: ''
    };
  }
}
