import { Timer } from './timer';
import { LoginTimerConfigurator } from './login-timer-configurator';
import { LoginTimerConfiguration } from './login-timer-configuration';

export class LoginTimer {
  private remainingTime;
  private loginTimerConfiguration: LoginTimerConfiguration;
  private timer: Timer
  private intervalId: any;
  private readonly STORED_TIME_KEY='noisses'

  constructor(
    {
      loginTimerConfigurator, timer
    }: {
      timer: Timer;
      loginTimerConfigurator: LoginTimerConfigurator;
    }
  ) {
    this.timer = timer;
    this.loginTimerConfiguration = loginTimerConfigurator.setup();
    this.remainingTime = this.loginTimerConfiguration.remainingTime;
  }

  saveNoisses(noisse: string){
    sessionStorage.setItem(this.STORED_TIME_KEY, noisse);
  }

  getNoisses(){
    return sessionStorage.getItem(this.STORED_TIME_KEY);
  }

  clearNoisses(){
    sessionStorage.removeItem(this.STORED_TIME_KEY);
  }

  getRemainingTime(): number {
    const noisses = this.getNoisses();
    if(!noisses) return this.remainingTime;

    this.remainingTime= +noisses;
    return this.remainingTime;
  }

  startCountdown({ callbackRemainingTime }: { callbackRemainingTime: (remainingTime: number) => void }) {
    const { interval } = this.loginTimerConfiguration;
    this.intervalId =this.timer.setInterval(() =>this.updateCountdown(callbackRemainingTime), interval);
  }

  private updateCountdown(callbackRemainingTime: (remainingTime: number) => void) {   
    if (this.isTimeOver()) {      
      clearInterval(this.intervalId);
      return;
    }
    this.saveNoisses(String(this.remainingTime-1));
    this.remainingTime--;
    callbackRemainingTime(this.remainingTime);
  }

  private isTimeOver() {
    const { timeLimit } = this.loginTimerConfiguration;
    return this.remainingTime === timeLimit;
  }
}
