import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ProgressIndicatorComponent } from './progress-indicator.component';

@NgModule({
  declarations: [
    ProgressIndicatorComponent
  ],
  exports: [
    ProgressIndicatorComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProgressIndicatorModule { }
