export const environment = {
  production: true,
  APIUX: 'https://loginwa-bautdesa.viabcp.com',
  APIACCESS: '/cas/oidc/accessToken',
  InsigthInstrumentation: '1aa8d094-7a65-4937-9f93-e49dc617b14a',
  NumberWsp: '14013378892',
  biomFacialVendor: {
    url: 'https://ecdneu2ciamd02.azureedge.net/$web/ng-facial-authorization-widget-v1/1.0.0/ciam-facial-authorization-widget.vendor.js',
    tag: 'ciam-facial-authorization-widget',
    waitTimeInSeconds: 300,
    facialBiometricUrl: 'https://apisux.ciamdev.viabcp.com/ux-biom-facial-authorization-v1/channel/biom/v1/'
  }
}
