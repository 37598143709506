<app-nav></app-nav> 
<div class="bg-primary-700" [ngClass]="{'loader-container': isLoading}">
    <bcp-loader-indicator
    type="indeterminate"
    mode="dark"
    [message]="messageLoading"
    *ngIf="!isValidation"
    #loaderIndicator
    (click)="closeLoaderIndicator()"
  ></bcp-loader-indicator>   

    <div class="flex gap-2 text-center" *ngIf="isValidation">
        <div class="mb-2" color="white">
          <img src="assets/image/icon_check.png" alt="validación Exitosa">
        </div>
        <bcp-paragraph color="white" family="demi">
          Identidad Validada
        </bcp-paragraph> 
    </div>
 </div>   

<div class="facial-container">

  <div class="facial-section">

    <div class="facial-row" >

      <ciam-facial-authorization-widget *ngIf="!isMessageError"
        #widgetElement
        [loadWidget]="loadWidget"
        (widgetEvent)="handleWidgetEvent($event)">
      </ciam-facial-authorization-widget>
       <div class="container pt-5 container-info"  *ngIf="isMessageError">
        <div class="col-md-6 mx-auto px-0">
          <div class="row" [align]="'center'">
            <div class="col">
              <img [src]="messageError.urlImg" [alt]="messageError.altTxtImg">
              <div class="pt-4">
                <bcp-title size="md" family="demi" color="primary-700">{{messageError.title}}</bcp-title>
              </div>
              <div *ngIf="messageError.subtitle" class="">
                <bcp-title size="sm" family="demi" color="primary-700">{{messageError.subtitle}}</bcp-title>
              </div>
              <div class="mt-4 mb-4 col-10">
                <bcp-paragraph  family="demi">
                  {{messageError.description}}
              </bcp-paragraph>
              </div>
              <div class="col-12" [style]="messageError.style">
                <bcp-button shape="rectangle" tier="primary" size="lg" *ngIf="messageError.click == 'validCustomer' && !isLoadingButton" (click)="validCustomer()"
                full-width="true"
                >{{messageError.textBtn}}</bcp-button>
                
                <bcp-button shape="rectangle" tier="primary" size="lg" *ngIf="messageError.click == 'validCustomer' && isLoadingButton"   full-width="true">
                  <bcp-loader-indicator type="indeterminate" mode="light" class="loading-button"  #loaderButton ></bcp-loader-indicator>  
                </bcp-button> 

                <bcp-button shape="rectangle" tier="primary" size="lg" *ngIf="!messageError.click" full-width="true" (ctrlClick)="redirectSend(messageError.redirectTo)">
                  {{messageError.textBtn}}
                </bcp-button> 

                

                <!--(ctrlClick)="errorRedirectSend(messageError.redirectTo)"-->
              </div>
            </div>
          </div>
        </div>
      </div>  
      <div class="biometric-footer">
        <app-footer></app-footer>
      </div>
    </div>

  </div>
  
</div>


