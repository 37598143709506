import { ErrorHandler, NgModule } from "@angular/core";
import { InsightService } from "./insight.service";
import {ApplicationinsightsAngularpluginErrorService} from '@microsoft/applicationinsights-angularplugin-js'


@NgModule({
    providers:[
        InsightService,
        {
            provide: ErrorHandler,
            useClass: ApplicationinsightsAngularpluginErrorService
        }
    ]
})

export class InsightModule {
    constructor(private insightService: InsightService){}
}