import { LoginTimerConfiguration } from '../../domain/login-timer-configuration';
import { LoginTimerConfigurator } from '../../domain/login-timer-configurator';

export class LoginTimerConfiguratorRepository implements LoginTimerConfigurator {
  setup(): LoginTimerConfiguration {
    return {
      interval: 1000,
      timeLimit: 0,
      remainingTime: 300,
    };
  }
}