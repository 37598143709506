<app-login-navbar 
  *ngIf="showNav"
  [remainingTime]="remainingTime"></app-login-navbar>
<app-login-form 
  *ngIf="customer"
  [customer]="customer"
  [showWarning]="showWarning"
  [errorCount]="numAttempt"
  (formValue)="handleFormValue($event)"
  >
</app-login-form>
