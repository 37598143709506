import { Injectable } from '@angular/core';
import { BcpAsyncLoaderHooks } from '@bcp/js-widgets';

@Injectable({
  providedIn: 'root'
})
export class WidgetsHookService implements BcpAsyncLoaderHooks {
  constructor() {}
  onStart(): void {
    console.log('(onStart) hook fired');
  }

  onEnd(): void {
    console.log('(onEnd) hook fired');
  }

  onError(error: string): void {
    console.log('(onError) hook fired', error);
  }
}