import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarStates {
  private isInitTimeSubject = new Subject<boolean>();
  isInitTime$ = this.isInitTimeSubject.asObservable();

  private isTimeFinishSubject = new Subject<boolean>();
  isTimeFinish$ = this.isTimeFinishSubject.asObservable();

  setIsInitTime(value: boolean): void {
    this.isInitTimeSubject.next(value);
  }

  sendIsFinishTime(value: boolean): void {
    this.isTimeFinishSubject.next(value);
  }
}