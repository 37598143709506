import { ErrorHandler, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AngularPlugin } from "@microsoft/applicationinsights-angularplugin-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { environment } from "src/environments/environment";


@Injectable()
export class InsightService {
    private angularPlugin = new AngularPlugin();
    private appInsight = new ApplicationInsights({
        config: {
            instrumentationKey: environment.InsigthInstrumentation,
            extensions: [this.angularPlugin],
            autoTrackPageVisitTime: true,
            extensionConfig: {
                [this.angularPlugin.identifier]: {
                    router: this.router,
                    errorServices: [new ErrorHandler()]
                }
            }
        }
    });

    constructor(private router: Router) {
        this.appInsight.loadAppInsights();
    }

    trackEvent(name: string, properties?: { [key: string]: any }): void {
        this.appInsight.trackEvent({ name }, properties);
    }

    trackTrace(message: string, properties?: { [key: string]: any }): void {
        this.appInsight.trackTrace({ message }, properties);
    }

    trackException(exception: any, severityLevel?: number): void {
        this.appInsight.trackException({ exception, severityLevel })
    }
}