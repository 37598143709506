import { CUSTOM_ELEMENTS_SCHEMA, NgModule,isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BcpAsyncLoader } from '@bcp/js-widgets';
import { CoreModule } from './contexts/login/infrastructure/core/core.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthManagerModule } from './core/auth-manager/auth-manager.module';
import { InsightModule } from './contexts/login/infrastructure/services/insight.module';
import { BiometricModule } from './contexts/biometric/application/biometric.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    AuthManagerModule,
    InsightModule,


    BiometricModule


  ],
  providers: [
    {
      provide: BcpAsyncLoader,
      useFactory: () => new BcpAsyncLoader(),
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
