import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MessageModal } from './message-modal';

@Component({
  selector: 'app-modal',
  template: `<bcp-modal #modal size="sm" height="288" (ctrlCloseModal)="ctrlCloseModal()">
  <bcp-modal-header accessible-name-close-icon="Cerrar">
    <bcp-title size="sm" color="primary-700" family="bold">
      {{messageModal.titleModal}}
    </bcp-title>
  </bcp-modal-header>
  <bcp-modal-body class="d-flex align-items-center pt-3">
    <bcp-paragraph size="md" family="regular">{{messageModal.descModal}}
    </bcp-paragraph>
  </bcp-modal-body>
  <bcp-modal-footer>
    <bcp-button (ctrlClick)="closeModal(messageModal.actionBtnModal)">
      {{messageModal.textBtnModal}}
    </bcp-button>
  </bcp-modal-footer>
</bcp-modal>`
})
export class ModalComponent implements OnInit {
  heigth: number = 0;
  messageModal!: MessageModal;
  @ViewChild('modal', { static: true }) modal!: ElementRef;

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    if (this.heigth !== 0) { this.setRender(this.heigth) }
  }

  openModal() {
    this.modal.nativeElement.open();
  }

  ctrlCloseModal() {
    this.setRender(288);
  }

  setRender(height: number) {
    this.renderer.setProperty(this.modal.nativeElement, 'height', height);
  }

  closeModal(action: any) {
    if (action === 'redirectTo') window.open('https://bcpzonasegurabeta.viabcp.com/#/generacion-de-clave-seis/ingreso-de-datos', '_blank', 'noopener');
    this.modal.nativeElement.close();
  }

}
