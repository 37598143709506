import { Component, OnInit } from '@angular/core';
import { NavbarStates } from '../../../domain/states/navbar.states';
import { ToolbarService } from '../../../infraestructure/services/toolbar.service';
import { BiometricService } from '../../../infraestructure/services/biometric.service';
import { BcpLocalStorage } from '@bcp/js-storage';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  storage = new BcpLocalStorage();
  initTime: boolean = false;  
  secondsRemaining = 0; 
  afterSecond : number = 0;
  isfinishTime : boolean =  this.biometricService.finishTime(); 
  localTime : boolean = this.biometricService.initTime();
  timerSubscription: Subscription | null = null; 
  constructor(private navbarStates : NavbarStates,
    private toolbarService : ToolbarService, public biometricService : BiometricService){  
     this.secondsRemaining = this.biometricService.secondTime();
  }

  ngOnInit() {
    this.navbarStates.isInitTime$.subscribe(isInitTime => { 
      this.initTime = isInitTime;
      this.initTimer();  
      this.execTimer();
    }); 

  }

  execTimer(){  
    if(this.biometricService.excTime()){
      try {
        this.toolbarService.getSecondsRemaining().subscribe((secondsRemaining) => { 
          this.isfinishTime = this.biometricService.excTime(); 
            this.biometricService.guardsecondTime(secondsRemaining);
            this.secondsRemaining = secondsRemaining;   
           
            if(this.secondsRemaining == 0 && this.isfinishTime){ 
              this.initTime = false;
              this.navbarStates.sendIsFinishTime(true);  
            }
            this.storage.setItem('initTime', 'TRUE');
          
         
        });
      } catch (error) {
        this.secondsRemaining = 0;
      }
      
    }
    
  } 
  initTimer() { 
    this.toolbarService.startCountdown(this.biometricService.secondTime());
  }
}
