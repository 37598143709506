import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ErrorComponent } from './error.component';

@NgModule({
  declarations: [
    ErrorComponent
  ],
  exports: [
    ErrorComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ErrorModule { }
