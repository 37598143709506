import { Injectable } from '@angular/core';
import { BcpLocalStorage } from '@bcp/js-storage';

@Injectable({
  providedIn: 'root'
})
export class AuthManager {
  private readonly keyToken = 'auth_token';
  storage = new BcpLocalStorage();
  saveToken(token: string) {
    sessionStorage.setItem(this.keyToken, token); 
    this.storage.setItem('accessToken', token);
    this.storage.setItem('tokenCasWD', token);
  }

  getToken() {
    return sessionStorage.getItem(this.keyToken);
  }
  
  clearToken(){
    sessionStorage.removeItem(this.keyToken);
    this.storage.removeItem('accessToken');
    this.storage.removeItem('tokenCasWD');
  }

  isValidToken() {
    const token = this.getToken();
    if (!token) {
      return false;
    }
    return true;
  }
}
