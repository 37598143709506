import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'; 
import { BiometricComponent } from './page/biometric/biometric.component'; 
import { OidcGuard } from '../domain/guard/cas.guard';

const routes: Routes = [
  { path: '', 
    canActivate: [OidcGuard],
    component: BiometricComponent,
    data: {
      queryParams: ['customerId','conversationId','channelId','chatbotId']
  } 
}
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BiometricRoutingModule { }
