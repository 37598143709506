import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, Subscription, takeWhile, timer } from 'rxjs';
import { BiometricService } from './biometric.service';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {

  private secondsRemaining = new BehaviorSubject<number>(this.biometricService.secondTime());

  private countdownSubscription: Subscription | null = null; // Almacena la suscripción al observable

  constructor(private biometricService : BiometricService) { }

  getSecondsRemaining(): Observable<number> {
    return this.secondsRemaining.asObservable();
  }

  startCountdown(initialSeconds: number) { 

    this.countdownSubscription?.unsubscribe();

    this.countdownSubscription = timer(0, 1000).pipe(
      map(secondsPassed => initialSeconds - secondsPassed),
      takeWhile(timeLeft => timeLeft >= 0)
    ).subscribe(secondsLeft => {
      this.secondsRemaining.next(secondsLeft);
    });
  }

  stopCountdown() {
    // Cancela la suscripción si existe
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
      this.countdownSubscription = null; // Limpiar la referencia a la suscripción
    }
  }
}
