import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { SessionRepository } from '../../domain/session-repository';
import { Session, SessionBody } from '../../domain/session';
import { environment } from 'src/environments/environment';
import { Params } from '@angular/router';

@Injectable()
export class SessionHttpRepository implements SessionRepository {
  private readonly httpClient = inject(HttpClient);
  private API_URL = `${environment.APIUX}/channel/baut/v1/customer-sessions`;

  async sendDataSession(params:Params, session: SessionBody): Promise<Session> {
    let queryParams = new HttpParams()
      .append("conversationId", params['conversationId'])
      .append("channelId", params['channelId'])
      .append("chatbotId", params['chatbotId']);

      let url = `${this.API_URL}/${params['customerId']}`;
    return lastValueFrom(this.httpClient.post<Session>(`${url}`, session, { params: queryParams }));
  }

}