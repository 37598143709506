import { Params } from '@angular/router';
import { CustomerRepository } from '../domain/customer-repository';

export class CreateCustomer {
  constructor(
    private configuration: {
      customerRepository: CustomerRepository;
    }
  ) { }

  run(params: Params) {
    const { customerRepository } = this.configuration;
    return customerRepository.getDataCustomer(params);
  }
}
