import { Component} from '@angular/core';

@Component({
  selector: 'app-correct-validation',
  styleUrls: ['./correct-validation.component.scss'],
  template: `<div class="fontSuccess d-flex align-items-center justify-content-center">
  <div class="text-center">
    <bcp-icon name="check-circle-b" size="25" color="white"></bcp-icon><br>
    <bcp-paragraph size="md" family="regular" color="white" class="pt-1">Identidad validada</bcp-paragraph>
  </div>
</div>`
})
export class CorrectValidationComponent { }
