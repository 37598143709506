import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CorrectValidationComponent } from './correct-validation.component';

@NgModule({
  declarations: [
    CorrectValidationComponent
  ],
  exports: [
    CorrectValidationComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CorrectValidationModule { }
