import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AuthInterceptor } from './auth-interceptor';
import { findToken } from './find-token';
import { AuthManager } from './auth-manager';
import { InsightService } from 'src/app/contexts/login/infrastructure/services/insight.service';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: findToken,
      deps: [HttpClient, AuthManager, InsightService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
})
export class AuthManagerModule { }