import { InjectionToken, NgModule } from '@angular/core';
import { CustomerHttpRepository } from './customer-http.repository';
import { HttpClientModule } from '@angular/common/http';
import { SessionRepository } from '../../domain/session-repository';
import { SessionHttpRepository } from './session-http.repository';

export const SESSION_REPOSITORY = new InjectionToken<SessionRepository>('SessionRepository');

@NgModule({
  providers: [
    CustomerHttpRepository,
    {
      provide: SESSION_REPOSITORY,
      useClass: SessionHttpRepository
    },
  ],
  imports: [
    HttpClientModule,
  ],
})
export class RepositoryModule { }