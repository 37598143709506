import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { AuthManager } from './auth-manager';
import { environment } from 'src/environments/environment';
import { InsightService } from 'src/app/contexts/login/infrastructure/services/insight.service';

export const findToken = (httpClient: HttpClient, authManager: AuthManager, insightService: InsightService) => async () => {
  if (!authManager.isValidToken()) {
    try {
      const { access_token } = await firstValueFrom(httpClient.request<{ access_token: string }>('GET', `${environment.APIUX}/cas/oidc/accessToken`));
      authManager.saveToken(access_token);
      insightService.trackTrace('Success call to api token');
    } catch (error) {
      insightService.trackException(`Error del servicio Get Token: ${JSON.stringify(error)}`);
    }
  }

  return Promise.resolve();
};