import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputPasswordViewComponent } from './input-password-view.component';
import { FormLoginModule } from './form/form.module';
import { NavbarModule } from './navbar/navbar.module';
import { FactoryCoreModule } from '../../factory-core.module';

@NgModule({
  declarations: [
    InputPasswordViewComponent
  ],
  imports: [
    FormLoginModule,
    NavbarModule,
    CommonModule,
    FactoryCoreModule
  ],
  exports: [
    InputPasswordViewComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InputPasswordViewModule { }
