import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ModalComponent } from './modal.component';

@NgModule({
  declarations: [
    ModalComponent
  ],
  exports: [
    ModalComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalModule { }
