import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SuccessComponent } from './success.component';

@NgModule({
  declarations: [
    SuccessComponent
  ],
  exports: [
    SuccessComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SuccessModule { }
