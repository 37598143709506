import { Component } from '@angular/core';
import { MessageError } from './message-error';

@Component({
  selector: 'app-error',
  template: `
  <bcp-navbar theme="dark" brand-logo="/assets/img/logos/bcp-dark-default.svg" fluid></bcp-navbar>
  <div class="container pt-5">
    <div class="col-md-6 mx-auto px-0">
      <div class="row" [align]="'center'">
        <div class="col">
          <img src={{messageError.urlImg}} alt={{messageError.altTxtImg}}>
          <div class="py-4">
            <bcp-title size="md" family="bold" color="primary-700">{{messageError.title}}</bcp-title>
          </div>
          <div class="mt-4 mb-5 col-10">
            <bcp-paragraph  family="demi">
              {{messageError.description}}
          </bcp-paragraph>
          </div>
          <div class="col-12 col-md-10" style={{messageError.style}}>
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (ctrlClick)="errorRedirectSend(messageError.redirectTo)">
              {{messageError.textBtn}}
            </bcp-button>
          </div>
        </div>
      </div>
    </div>
  </div>`
})
export class ErrorComponent {

  messageError!: MessageError;

  errorRedirectSend(redirectTo: string | null) {
    if (redirectTo) {
      window.open(redirectTo, '_blank', 'noopener');
    }
  }
}
