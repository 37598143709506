import { Injectable, ViewContainerRef } from '@angular/core';
import { SuccessComponent } from './success.component';

@Injectable({
    providedIn: 'root'
})
export class SuccessService {
    viewContainerRef!: ViewContainerRef;

    open() {
        this.viewContainerRef.clear();
        const ref = this.viewContainerRef.createComponent(SuccessComponent);
        ref.changeDetectorRef.detectChanges();
    }

    close() {
        this.viewContainerRef.remove();
    }
}
