import { MessageError } from "./message-error";
import { environment } from "src/environments/environment";

export class ErrorMessageFactory {
    linkExpired(): MessageError {
        return {
            urlImg: '/assets/svg/error_general.svg',
            altTxtImg: 'Link Expiro',
            title: 'El link ha expirado',
            description: 'Este link ya no funciona. Puede ser que ya lo hayas utilizado o que se cumplió su tiempo de vigencia. Por favor, iniciar sesión en el WhatsApp del BCP.',
            textBtn: 'Iniciar sesión en Whatsapp',
            style: 'margin-top: 2rem',
            redirectTo: `https://wa.me/${environment.NumberWsp}`
        };
    }

    serviceErrorGeneral(): MessageError {
        return {
            urlImg: '/assets/svg/error-service.svg',
            altTxtImg: 'Error del servicio',
            title: 'Algo no ha salido bien',
            description: 'Tenemos problemas y no podemos continuar. Por favor, vuelve a intentarlo en unos minutos.',
            textBtn: 'Chatea con un asesor',
            style: 'top: 20%',
            redirectTo: `https://wa.me/${environment.NumberWsp}?text=Deseo%20hablar%20con%20un%20asesor`
        };
    }

    servicePostError(): MessageError {
        return {
            urlImg: '/assets/svg/error-service.svg',
            altTxtImg: 'Algo ha ocurrido',
            title: '¡Algo ha ocurrido!',
            description: 'Por favor, verifica si recibiste respuesta a tu consulta en WhatsApp o intenta realizarla nuevamente para que podamos ayudarte.',
            textBtn: 'Entendido',
            style: 'margin-top: 8rem',
            redirectTo: `https://wa.me/${environment.NumberWsp}`
        };
    }

    sessionExpired(): MessageError {
        return {
            urlImg: '/assets/svg/error-timeout.svg',
            altTxtImg: 'Sesion expirada',
            title: 'El tiempo se ha agotado',
            description: 'Por tu seguridad, vuelve a iniciar sesión a través del WhatsApp del BCP.',
            textBtn: 'Ir a WhatsApp',
            style: 'margin-top: 7rem',
            redirectTo: `https://wa.me/${environment.NumberWsp}`
        };
    }

    attempsPassed(): MessageError {
        return {
            urlImg: '/assets/svg/error-pwd.svg',
            altTxtImg: 'Error de password',
            title: 'Superaste los intentos permitidos',
            description: 'Por seguridad, tu tarjeta ha sido bloqueada por 24 horas.',
            textBtn: 'Entendido',
            style: 'margin-top: 5rem',
            redirectTo: `https://wa.me/${environment.NumberWsp}`
        };
    }

    cardBlocked(): MessageError {
        return {
            urlImg: '/assets/svg/error-pwd.svg',
            altTxtImg: 'Tarjeta bloqueada',
            title: 'Tu tarjeta está bloqueada',
            description: 'Intentaste ingresar con una tarjeta que está bloqueada. Inténtalo nuevamente mañana.',
            textBtn: 'Entendido',
            style: 'top: 20%',
            redirectTo: `https://wa.me/${environment.NumberWsp}`
        };
    }

    serviceErrorSendData(): MessageError {
        return {
            urlImg: '/assets/svg/error_general.svg',
            altTxtImg: 'Error del servicio',
            title: 'Algo no ha salido bien',
            description: 'Tenemos problemas y no podemos continuar. Por favor, vuelve a intentarlo en unos minutos.',
            textBtn: 'Habla con asesor por Whatsapp',
            style: 'margin-top: 5rem',
            redirectTo: null
        };
    }

    serviceErrorGetAuthentication(): MessageError {
        return {
            urlImg: '/assets/svg/error_authentication.svg',
            altTxtImg: 'Error del servicio',
            title: 'Ocurrio un error inesperado',
            description: 'Estamos trabajando para solucionarlo lo antes posible. Vuelve a intentarlo más tarde.',
            textBtn: 'Salir',
            style: 'margin-top: 5rem',
            redirectTo: `https://wa.me/${environment.NumberWsp}?text=Deseo%20hablar%20con%20un%20asesor`
        };
    } 

    attempsPassedBiom(): MessageError {
        return {
            urlImg: '/assets/svg/error-service.svg',
            altTxtImg: 'Error del servicio',
            title: 'Algo no ha salido bien',
            description: 'No has podido validar tu identidad. Por favor, vuelve a intentarlo.',
            textBtn: 'Volver a intentarlo',
            style: 'margin-top: 5rem',
            redirectTo: null
        };
    }

    serviceErrorGetData(): MessageError {
        return {
            urlImg: '/assets/svg/error-service.svg',
            altTxtImg: 'Error del servicio',
            title: 'Algo no ha salido bien',
            description: 'Tenemos problemas y no podemos continuar. Por favor, vuelve a intentarlo en unos minutos.',
            textBtn: 'Chatea con un asesor',
            style: 'margin-top: 5rem',
            redirectTo: `https://wa.me/${environment.NumberWsp}?text=Deseo%20hablar%20con%20un%20asesor`
        };
    }

    serviceErrorReceptApi(): MessageError {
        return {
            urlImg: '/assets/svg/error_api.svg',
            altTxtImg: 'Error del servicio',
            title: 'Algo no ha salido bien',
            description: 'Tenemos problemas y no podemos continuar. Por favor, vuelve a intentarlo en unos minutos.',
            textBtn: 'Iniciar sesión en Whatsapp',
            style: 'margin-top: 5rem',
            redirectTo: `https://wa.me/${environment.NumberWsp}`
        };
    }
}