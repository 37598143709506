import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BiometricComponent } from './page/biometric/biometric.component';
import { InputPasswordViewModule } from '../../login/infrastructure/views/input-password-view/input-password-view.module';
import { FactoryCoreModule } from '../../login/infrastructure/factory-core.module';
import { BiometricRoutingModule } from './biometric-routing.module';
import { NavComponent } from './components/nav/nav.component';
import { WidgetsModule } from './widget.module'; 
import { HttpClientModule } from '@angular/common/http';
import { FooterComponent } from './components/footer/footer.component';


const COMPONENTS = [
  BiometricComponent,
  NavComponent,
  FooterComponent
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    BiometricRoutingModule, 
    InputPasswordViewModule,
    FactoryCoreModule,
    WidgetsModule,
    HttpClientModule
  ],
  providers:[ 
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BiometricModule { }
