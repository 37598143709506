import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BcpAsyncLoader } from '@bcp/js-widgets';
import { environment } from 'src/environments/environment'; 
import { NavbarStates } from '../../../domain/states/navbar.states';
import { ErrorMessageFactory } from 'src/app/contexts/login/infrastructure/core/components/error/error-message-factory';
import { MessageError } from 'src/app/contexts/login/infrastructure/core/components/error/message-error';
import { BiometricService } from '../../../infraestructure/services/biometric.service'; 
import { ToolbarService } from '../../../infraestructure/services/toolbar.service';
import { LogInsight } from 'src/app/contexts/login/domain/log-insight';
import { InsightService } from 'src/app/contexts/login/infrastructure/services/insight.service';
@Component({
  selector: 'app-biometric',
  templateUrl: './biometric.component.html',
  styleUrls: ['./biometric.component.scss']
})
export class BiometricComponent  implements OnInit, AfterViewInit {
  @Input() remainingTime = 0;
  @ViewChild('loaderIndicator') loaderIndicator! : ElementRef<any> | undefined;
  @ViewChild('loaderButton') loaderButton! : ElementRef<any> | undefined;
  isLoading : boolean = true; 
  messageLoading : string = 'Espere un momento por favor';
  isLoadingButton : boolean = false;
  isTimeFinish : boolean = false;
  isVerificationTimer : boolean = true;
  isValidation : boolean = false;
  isErrorAuthentication : boolean = true;
  isMessageError : boolean = false;  
  private readonly errorMessageFactory = new ErrorMessageFactory();
  facialKey: string = '';
  messageError: MessageError = {
    urlImg: '/assets/svg/error-pwd.svg',
    altTxtImg: 'tomar una foto',
    title: 'Valida tu identidad tomándote una foto',
    description: 'Ve a un lugar luminado y, por favor, no cubras tu rostro.',
    textBtn: 'Continuar',
    style: '',
    redirectTo: null
  };

  @ViewChild('widgetElement') widgetElement!: ElementRef;
  loadWidget = false;
  messageToWidget = {};

  constructor( private asyncLoader: BcpAsyncLoader,
    public navbarStates : NavbarStates,
    private biometricService : BiometricService, 
    private toolbarService : ToolbarService,
    private cdr: ChangeDetectorRef,
    private insightService : InsightService){ 
  }

  ngOnInit() { 
    this.isLoading = true;   
  }

  async ngAfterViewInit(){   
    this.openLoaderIndicator(); 
    try {
      const isAuthenticate = await this.biometricService.authenticate();
      if (isAuthenticate) {
        this.infoWidgetBiometric();
      } else {
        this.biometricService.invalide();
        this.isErrorAuthentication = this.biometricService.errorAuthentication();
        this.infoMessageError(1);
       
      }
    } catch (error) {
      this.biometricService.invalide();
      this.isErrorAuthentication = this.biometricService.errorAuthentication();
      this.infoMessageError(1);
    } 
    this.cdr.detectChanges();
  }

  async infoWidgetBiometric(){ 
    try {
      await this.handleResponse();
    } catch (error) { 
      this.infoMessageError(1);
    }
 
    this.navbarStates.isTimeFinish$.subscribe(isTimeFinish => { 
        this.isTimeFinish = isTimeFinish;  
        if(this.isTimeFinish && this.isVerificationTimer){ 
          this.infoMessageError(3); 
          this.biometricService.clearDataBiom();
        }
    });
  }
 
  async handleResponse() { 
    if(this.biometricService.biometricCode()){ 
      await this.loadWidgetAsync(); 
      const message = {  appCode: 'OT',  processDataCode: this.biometricService.biometricCode() }; 
      this.initWidget(message);
    }else{ 
      this.infoMessageError(4);   
    } 
  }

  initTimer() {  
    this.navbarStates.setIsInitTime(true); 
  }

  finishTimer(){
    this.toolbarService.stopCountdown();
  }

  openLoaderIndicator() {
    this.loaderIndicator?.nativeElement.open(); 
  }
  openLoaderButton() {
    this.loaderButton?.nativeElement.open(); 
  }

  closeLoaderIndicator() {
    this.isLoading = false;
    this.loaderIndicator?.nativeElement.close();
    this.initTimer();
  }

  closeLoaderButton() {
    this.loaderButton?.nativeElement.close(); 
  }

  initWidget(message : any){ 
    this.loadWidget = true;
    this.sendMessageToWidget(message);
  }

  sendMessageToWidget(message : any) { 
    this.widgetElement.nativeElement.widgetMethod(message); 
    this.infoMessageError(5);   
  }

  handleWidgetEvent(event: Event): void {
    const { detail } = event as CustomEvent;
    switch(detail.state) {
        case 'successful': 
            this.isVerificationTimer = false;
            this.facialKey = detail.payload.processData.facialAuthenticationKey; 
            this.validCustomer(); 
            break;
        case 'denied': 
            this.isMessageError = false;
            break
        case 'exit':
          this.navbarStates.setIsInitTime(false);  
          this.navbarStates.sendIsFinishTime(true);  
          this.finishTimer();
            this.biometricService.clearDataBiom();
            window.open(`https://wa.me/${environment.NumberWsp}`,'_blank','noopener');
            
            break
    }
  }

  async loadWidgetAsync(): Promise<void> {
    await this.asyncLoader.load(
      environment.biomFacialVendor.url,
      environment.biomFacialVendor.tag
    ); 
  }
 
 
  async validCustomer(){
   // this.navbarStates.sendIsFinishTime(true); 
    this.isLoading = true;
    this.messageLoading = 'Un momento más por favor';
    this.openLoaderIndicator(); 
    try {
      const isValidation = await this.biometricService.validation(this.facialKey);
      if(isValidation){ 
        this.loaderIndicator?.nativeElement.close();
        this.isValidation = true;
        //this.closeLoaderIndicator();
        setTimeout( () =>{
          this.validationTrue();
          
        },3000); 
      }else{ 
        this.isLoading = false;
        this.loaderIndicator?.nativeElement.close();
        this.biometricService.invalide();
        this.isErrorAuthentication = this.biometricService.errorAuthentication();
        this.infoMessageError(8); 
        let logInsight: LogInsight = {  message: 'Respuesta Error del endpoint verify' };
        this.insightService.trackTrace(`${JSON.stringify(logInsight)}`)
      } 
    } catch (error : any) { 
      this.isLoading = false;
      this.loaderIndicator?.nativeElement.close(); 
      this.biometricService.invalide();
      this.isErrorAuthentication = this.biometricService.errorAuthentication();
      this.infoMessageError(8); 
      let logInsight: LogInsight = { statusCode: error?.status, statusText: error?.statusText, message: 'Error del servicio del endpoint verify', response: error?.error };
      this.insightService.trackException(`${JSON.stringify(logInsight)}`)
      
    } 
     

  }

  validationTrue(){
    this.isValidation = false;
          this.isLoading = false; 
          this.isMessageError = true; 
          this.navbarStates.setIsInitTime(false);  
          this.navbarStates.sendIsFinishTime(true);  
          this.finishTimer();
          this.biometricService.clearDataBiom(); 
          this.messageError = {
            urlImg: '/assets/svg/success_img.svg',
            altTxtImg: 'tomar una foto',
            title: '¡Felicitaciones!',
            subtitle: 'Ya puedes seguir con tus consultas en el Whatsapp del BCP',
            description : 'En breve te llegará una notificación a tu Whatsapp confirmando tu inicio de sesión',
            textBtn: 'Volver al whatsapp del BCP',
            style: '',
            redirectTo: `https://wa.me/${environment.NumberWsp}`,
          };
  }

  infoMessageError(type : number){
    this.isMessageError = true;
    this.isLoading = false;
    switch (type) {
      case 1: //Error de validación de datos params
        this.navbarStates.sendIsFinishTime(true);  
        this.finishTimer();
        this.messageError = this.errorMessageFactory.serviceErrorGetAuthentication(); 
        this.loaderIndicator?.nativeElement.close();
        break;

      case 2: //Error de consulta de Usuario
        this.messageError = this.errorMessageFactory.serviceErrorSendData(); 
        this.loaderIndicator?.nativeElement.close();
        this.isVerificationTimer = false;
        this.navbarStates.sendIsFinishTime(true); 
        break;
      
      case 3: //Error de termino de tiempo
        this.messageError = this.errorMessageFactory.sessionExpired();  
        this.finishTimer();
        break;

      case 4: //Error de response?.personData?.state diferente de A
        this.loaderIndicator?.nativeElement.close();
        break;
      
      case 5: //Limpiar datos esta ok mostrar informacion
        this.isMessageError = false;
        this.loaderIndicator?.nativeElement.close();
       this.initTimer();
        break;

      case 6: //Limpiar datos
        this.isMessageError = true;
        this.loaderIndicator?.nativeElement.close();
        this.initTimer();
        break;

      case 7: //Error de termino de tiempo
        this.messageError = this.errorMessageFactory.attempsPassedBiom(); 
        break;
      
      case 8: //Error de ultimo endpoint 
        this.navbarStates.sendIsFinishTime(true); 
        this.finishTimer(); 
        this.isVerificationTimer = false;  
        this.loaderIndicator?.nativeElement.close();
        this.messageError = this.errorMessageFactory.serviceErrorReceptApi();  
        
        break;

      default:
        break;
    } 
   
    
  }

  redirectSend(redirectTo: string | null) {
    if (redirectTo) {
      window.open(redirectTo, '_blank', 'noopener');
    }
  }
}
