import { Component, ElementRef, OnInit, ViewChild, inject, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BcpValidators } from '@bcp/ng-forms';
import { Subscription } from 'rxjs';
import { BcpAsyncLoader } from '@bcp/js-widgets';
import { Customer } from '../../../../domain/customer';
import { KeyboardSend } from '../../../../domain/session';
import { ModalService } from 'src/app/contexts/login/infrastructure/core/components/modal/modal.service';
import { ModalMessageFactory } from './modal-message.factory';

@Component({
  selector: 'app-login-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormLoginComponent implements OnInit, OnChanges {
  @ViewChild('pwd') inputPasswordElement!: ElementRef;
  @Output() formValue = new EventEmitter<KeyboardSend>();
  @Input() customer!: Customer;
  @Input() showWarning = false;
  @Input() errorCount = 0;

  private readonly modalService = inject(ModalService);
  private readonly asyncLoader = inject(BcpAsyncLoader);
  private modalMessageFactory = new ModalMessageFactory();
  private subscription = new Subscription();
  visible = false;
  passwordControl = new FormControl<KeyboardSend>({ keys: [], seed: '' }, [BcpValidators.required, BcpValidators.lengthInputPassword(6)]);

  stateError?: string;

  async ngOnInit(): Promise<void> {
    this.asyncLoader.load('assets/bcp-input-password.vendor.js', 'bcp-input-password').then(() => {
      this.inputPasswordElement.nativeElement.requestCallFn((done: any) => {
        done({
          keys: this.customer.keyboard.keys,
          seed: this.customer.keyboard.seed
        });
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['errorCount']?.currentValue === 2) this.openModalToLastAttemp();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openModalToCreatePassword() {
    this.modalService.open(this.modalMessageFactory.createPassword(), 0);
  }

  openModalToForgotPassword() {
    this.modalService.open(this.modalMessageFactory.forgotPassword(), 0);
  }

  openModalToLastAttemp() {
    this.modalService.open(this.modalMessageFactory.lastAttemp(), 240);
  }

  reset() {
    this.passwordControl.reset();
  }

  closeWarning() {
    this.showWarning = false;
  }

  sendForm() {
    this.showWarning = false;
    if (this.isValidForm()) {
      this.formValue.emit(this.passwordControl.value!);
      this.reset();
    }
  }

  isValidForm() {
    return this.passwordControl.valid;
  }
}
