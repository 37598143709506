import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-loading',
  template: `<bcp-progress-indicator type="indeterminate" mode="dark" message="Espere un momento por favor" #progressIndicator> </bcp-progress-indicator>`
})
export class ProgressIndicatorComponent {

  @ViewChild('progressIndicator', { static: true }) progressIndicator!: ElementRef;

  openProgress() {
    this.progressIndicator.nativeElement.open();
  }

  closeProgress() {
    this.progressIndicator.nativeElement.close();
  }
}
